import { FunctionComponent, useEffect, useState } from "react";
import styles from "./WalletSelector.module.css";

type WalletSelectorProps = {
	onConnectUnisat: () => void;
	onConnectXverse: () => void;
};

type WalletOption = {
	key: string;
	title: string;
	icon: string;
	onClick: () => void;
	isSelected: boolean;
};

const WalletSelector: FunctionComponent<WalletSelectorProps> = ({ onConnectUnisat, onConnectXverse }) => {
	const [selectedWallet, setSelectedWallet] = useState<string | null>(null);

	const selectUnisat = () => {
		setSelectedWallet("unisat");
		onConnectUnisat();
	};

	const selectXverse = () => {
		setSelectedWallet("xverse");
		onConnectXverse();
	};

	const walletOptions: WalletOption[] = [
		{
			key: "unisat",
			title: "Unisat",
			icon: "/wallet-unisat@2x.png",
			onClick: selectUnisat,
			isSelected: selectedWallet === "unisat",
		},
		{
			key: "xverse",
			title: "Xverse",
			icon: "/wallet-xverse@2x.png",
			onClick: selectXverse,
			isSelected: selectedWallet === "xverse",
		},
	];

	return (
		<div className={styles.walletSelector} id="wallet_selector_container">
			<img className={styles.image6Icon} alt="" src="/image-6@2x.png" />
			<h1 className={styles.connectAWallet} id="title">
				Connect a wallet to continue
			</h1>
			<p className={styles.chooseHowYou} id="instructions">
				Choose how you want to connect. If you don’t have a wallet, you can
				select a provider and create one.
			</p>
			<div className={styles.walletOptionsContainer}>
				{walletOptions.map((wallet) => (
					<button
						key={wallet.key}
						className={wallet.isSelected ? styles.walletTypeSelected : styles.walletTypeUnselected}
						onClick={wallet.onClick}
					>
						<img className={styles.walletImageIcon} alt="" src={wallet.icon} />
						<b className={styles.walletTitle}>{wallet.title}</b>
						<div className={wallet.isSelected ? styles.radioButtonSelected: styles.radioButtonUnselected } />
					</button>
				))}
			</div>
		</div>
	);
};

export default WalletSelector;
