import {useEffect, useState} from "react";
import FeeRatesService from '../../services/feeService';
import styles from "./FeeSelectionContainer.module.css";
import {InscriptionDataConfiguration} from "../../services/apiEndpoints";
import {Tabs} from "../FileContainer/FileContainer";

interface FeeRateData {
	max_90th_percentile: number,
	max_75th_percentile: number,
	max_median: number,
	recommended: number,
	ordinal_reuse_minimum: number,
	common: { recommendedFeesResponse: { minimumFee: number } },
}

function FeeRatesList({
	selectedRate,
	onRateSelected,
	inscriptionDataConfiguration,
}: Props) {
	const [feeRates, setFeeRates] = useState<any[]>([]);
	const [customRate, setCustomRate] = useState<number>(feeRates[0] || 0);
	const canHaveMinRate = (inscriptionDataConfiguration.rarity?.label === "COMMON" || inscriptionDataConfiguration.rarity?.pricingInfo?.multiplicity.max === 1)
	let commonMinRate = 3

	useEffect(() => {
		//First get Network Fee Rates
		FeeRatesService.getFeeRates()
			.then((data: FeeRateData) => {
				const rates = [
					data.ordinal_reuse_minimum, // Economy
					data.max_75th_percentile, // Normal
					data.max_90th_percentile, // Urgent
				]
				setFeeRates(rates)
				commonMinRate = data.common.recommendedFeesResponse.minimumFee
				// Autoselect first rate
				onRateSelected(rates[0])
				setCustomRate(canHaveMinRate ? commonMinRate : rates[0])
			})
			.catch(error => console.error('Error fetching rates:', error));
	}, []);

	const isRateSelected = (rateIndex: number): boolean => {
		if (rateIndex === 3) {
			return selectedRate === customRate
		}
		return feeRates[rateIndex] === selectedRate
	}

	const onSelectRate = (rateIndex: number) => {
		if (feeRates[rateIndex] && feeRates.length) {
			onRateSelected(feeRates[rateIndex])
		} else if (rateIndex === 3) {
			onRateSelected(customRate)
		}
	}

	const handleCustomRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		console.log(event.target.value)
		const newCustomRate = Number(event.target.value);
		if (newCustomRate) {
			console.log("NEW RATE", newCustomRate, typeof newCustomRate)
			onRateSelected(newCustomRate);
			setCustomRate(newCustomRate);
		} else {
			console.error("Invalid value for custom rate:", event.target.value);
		}
	}

	return (
		<div className={styles.selectFeeContainer}>
			<div className={styles.selectFeeParent}>
				<div className={styles.feeSelectorContainer}>
					<button type="button" className={`${styles.feeOption} ${isRateSelected(0) ? styles.active : ''}`} onClick={() => onSelectRate(0)}>
						<div className={styles.satTypeTitle}>Economy</div>
						<div className={styles.feeRateAmount}>{feeRates[0] ?? "--"} sats/vB</div>
						<div className={styles.feeRateEte}>~1 Hour</div>
					</button>
					<button type="button" className={`${styles.feeOption} ${isRateSelected(1) ? styles.active : ''}`} onClick={() => onSelectRate(1)}>
						<div className={styles.satTypeTitle}>Normal</div>
						<div className={styles.feeRateAmount}>{feeRates[1] ?? "--"} sats/vB</div>
						<div className={styles.feeRateEte}>~30 min</div>
					</button>
					<button type="button" className={`${styles.feeOption} ${isRateSelected(2) ? styles.active : ''}`} onClick={() => onSelectRate(2)}>
						<div className={styles.satTypeTitle}>Urgent</div>
						<div className={styles.feeRateAmount}>{feeRates[2] ?? "--"} sats/vB</div>
						<div className={styles.feeRateEte}>~10 Minutes</div>
					</button>
					<button type="button" className={`${styles.feeOption} ${isRateSelected(3) ? styles.active : ''}`} onClick={() => onSelectRate(3)}>
						<div className={styles.satTypeTitle}>Custom</div>
						<div className={styles.feeRateAmount}>{customRate ?? "--"} sats/vB</div>
						<div className={styles.feeRateEte}>
							<input 
								type="range" 
								min={canHaveMinRate ? commonMinRate : feeRates[0]}
								max="500" 
								value={customRate} 
								onChange={handleCustomRateChange}
								/>
						</div>
					</button>
				</div>
				<div className={styles.timesAndUsd}>
					Times and USD values are estimates only
				</div>
			</div>
		</div>
	);
}

interface Props {
	selectedRate: number;
	onRateSelected: (newRate: number) => void;
	inscriptionDataConfiguration: InscriptionDataConfiguration;
}

export default FeeRatesList;
