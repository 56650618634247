
import styles from "../FileContainer.module.css";

import styles2 from "./Attention.module.css";

const BYOSAttention = ({
	onNext
}: Props) => {

	return <div>
		<h2 className={styles2.header}>Pay Attention!</h2>
		<span className={styles2.subheader}>You will be performing two transactions.</span>
		<ol type="1">
			<li>
				On the next view you will fund your inscription. This will cover network and inscription fees.
				You can use Unisat, Xverse, or any other wallet to do this.
			</li>
			<li>
				After you fund your inscription you will then need to send the UTXO containing the rare or exotic
				sat you want to inscribe on to an address we will tell you. Typically, Xverse or Sparrow is used to manage
				Rare and Exotic Sat UTXOs. If you are using Sparrow, ensure that any other UTXOs you do not want to send are frozen and make sure you are sending
				the correct UTXO.
			</li>
		</ol>
		<button
			className={styles.payButton}
			onClick={onNext}
		>
			<div className={styles.payButtonChild}/>
			<b className={styles.uploadPay}>Next</b>
		</button>
	</div>
}

interface Props {
	onNext: () => void;
}


export default BYOSAttention

