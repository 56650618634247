class APIService {
  async fetchData(url: string): Promise<any> {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Request failed');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('API request error:', error);
      throw error;
    }
  }

	async postData(url: string, data: any): Promise<any> {
		try {
			const formData = new URLSearchParams();
			for (const key in data) {
				formData.append(key, data[key]);
			}

			const response = await fetch(url, {
				method: 'POST',
				body: formData,
			});

			const responseData = await response.json();

			if (!response.ok) {
				throw new Error(responseData.message || 'Request failed');
			}
			return responseData;
		} catch (error) {
			console.error('API request error:', error);
			throw error;
		}
	}

  async postInscription(url: string, data: any, textOverrideContents: string | null = null): Promise<any> {
    try {
      const formData = new FormData();
      for (const key in data) {
        if (key === "inscriptions"){
          if(textOverrideContents) {
            formData.append('inscriptions', new Blob([textOverrideContents], { type: 'text/plain' }), 'inscriptionTextFile.txt')
          } else {
            (data[key] as File[]).forEach(file => formData.append("inscriptions", file))
          }
          // console.log(formData.get("inscriptions"))
          // debugger
        }else{
          if(key === 'rarity' && data[key] === 'COMMON'){
            continue;
          }
          formData.append(key, data[key]);
        }
      }
  
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || 'Request failed');
      }
  
      return responseData;
    } catch (error) {
      console.error('API request error:', error);
      throw error;
    }
  }

}




export default new APIService();
