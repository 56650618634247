import APIService from './apiService';
import {
	CHECK_STATUS_ENDPOINT,
	COUPON_ENDPOINT,
	GENERATE_PIZZA_ARTIFACT_COUPON_CHALLENGE,
	VERIFY_PIZZA_ARTIFACT_HOLDER
} from './apiEndpoints';
import apiService from "./apiService";

class CouponService {

	async getCouponData(coupon: string) {
		const url = `${COUPON_ENDPOINT.replace(`{coupon_code}`, coupon)}`
		console.log("coupon serice", url)
		return APIService.fetchData(url);
	}

	async getVerifyPizzaArtifactHolderData(walletAddress: string) {
		const url = `${VERIFY_PIZZA_ARTIFACT_HOLDER.replace(`{wallet_address}`, walletAddress)}`
		console.log("verify pizza artifact holder service", url)
		return APIService.fetchData(url);
	}

	async generatePizzaArtifactCouponChallenge() {
		console.log("generate pizza artifact holder challenege")
		return APIService.postInscription(GENERATE_PIZZA_ARTIFACT_COUPON_CHALLENGE, null, null);
	}

}

export default new CouponService();
