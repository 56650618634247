
import styles from "../FileContainer.module.css";

const BYOSFunding = ({
	fundingAddress
}: Props) => {

	return <div>
		heelo this is funding address: {fundingAddress}
		<button
			className={styles.payButton}
			onClick={() => {

				alert("hello")
			}}
		>
			<div className={styles.payButtonChild}/>
			<b className={styles.uploadPay}>Next</b>
		</button>
	</div>
}

interface Props {
	fundingAddress: string;
}


export default BYOSFunding

