import {FunctionComponent, useState, useCallback, useEffect, useMemo} from "react";
import {useDropzone} from 'react-dropzone';
// import { setInscriptionStep } from '../Insc'
import styles from "./FileContainer.module.css";
import apiService from "../../services/apiService";
import {
	BITCOIN_PRICE_ENDPOINT_V2, CHECK_STATUS_ENDPOINT, CHISEL_CHECK_STATUS_ENDPOINT,
	InscriptionDataConfiguration,
	SAT_TYPE_PRICING_ENDPOINT,
	SatPricing
} from "../../services/apiEndpoints";
import {IconButton, Tooltip, Typography} from "@mui/material";
import TransactionStatus, {StatusResponse} from "../TransactionStatus/TransactionStatus";
import {useNavigation, useParams, useSearchParams} from "react-router-dom";
import BYOSAttention from "./BYOS/Attention";

const SatDescriptions: Record<string, string> = {
	"UNCOMMON_2022": "First sat of each block.",
	"UNCOMMON_2023": "First sat of each block.",
	"JPEG": "First Bitcoin trade for an image on February 24, 2010",
	"BLOCK_9": "450x Sats from the earliest Bitcoin in circulation. The first Bitcoin mined by Satoshi from Block 9.",
	"BLOCK_78": "Sats mined by Hal Finney in block 78 which was the first block mined by someone other than Satoshi.",
	"BLOCK_286": "Sat mined by Satoshi Nakamoto.",
	"PIZZA": "Sat from the 10,000 Bitcoins used by Laszlo Hanyecz to purchase two Papa John's pizzas on May 22, 2010.",
	"SILK_ROAD_HIT": "Sats used by Ross Ulbricht to hire a hitman through Silk Road.",
	"SILK_ROAD_REGULAR": "Sats used on the Silk Road platform.",
	"COMMON": "Any sat that is not the first sat of its block.",
	"PALINDROME_NUMERIC": "Sat whos number reads the same backward or forward.",
	"HALLOWEEN_2009": "Sats mined on Halloween in 2009. Halloween 2009 was the first anniversary of the Bitcoin whitepaper.",
	"CHRISTMAS_2009": "Sats mined on Christmas in 2009.",
	"FIRST_HALVING": "Sats mined on Nov. 28, 2012 — three years and 10 months after Bitcoin's first block was mined — the first-ever halving event took place.",
	"BLOCK_666": "Sats from the first monodigit block number to go into circulation."
}

const SatTypeTile = ({
	isSelected,
	pricing,
	onTileClicked
}: {
	isSelected: boolean;
	pricing: SatPricing;
	onTileClicked: () => void;
}) => {
	return <Tooltip
		title={SatDescriptions[pricing.name] &&
			<Typography variant="subtitle2">{SatDescriptions[pricing.name]}</Typography>}
		placement="top"
		arrow
	>
		<button className={isSelected ? styles.satTypeSelector1 : styles.satTypeSelector2} onClick={() => onTileClicked()}>
			<div className={isSelected ? styles.satTypeTitle : styles.satTypeTitle1}>{pricing.label}</div>
			<b className={isSelected ? styles.satTypePrice : styles.satTypePrice1}>{pricing.priceDisplay}</b>
		</button>
	</Tooltip>
}

type FileContainerProps = {
	setInscriptionStep: (step: number) => void;
	setInscriptionDataConfiguration: React.Dispatch<React.SetStateAction<InscriptionDataConfiguration>>;
	setTab: (tab: Tabs) => void;
	setFundingAddress: (address: string) => void;
};

interface Multiplicity {
	total: number;
	min: number;
	max: number;
}


export interface SatTypePricing {
	multiplicity: Multiplicity;
	utxoCount: number;
	available: {
		multiplicity: Multiplicity;
		utxoCount: number;
	};
	usdCentPrice: number;
}

export enum Tabs {
	File = "file",
	Text = "text",
	Uninscribed = "uninscribed",
	BYOS = 'byos',
	BYOS_Attention = "byos-attention",
	BYOS_Address = "byos-address",
}

function FileContainer({
	setInscriptionStep,
	setInscriptionDataConfiguration,
	setTab,
	setFundingAddress,
}: FileContainerProps) {
	const [isInscribeFeeModalPopupOpen, setInscribeFeeModalPopupOpen] = useState(false);

	const onImage1Click = useCallback(() => {
		// window.open("https://twitter.com/RareSatSociety");
	}, []);

	const [types, setTypes] = useState<SatPricing[]>([])
	useEffect(() => {
		(async () => {
			try {
				const response: Record<string, SatTypePricing> = await apiService.fetchData(BITCOIN_PRICE_ENDPOINT_V2)
				//add common sats to response

				Object.assign(response, {"COMMON": null})
				const prices = Object.entries(response)
					.map(entry => {
						if (entry[0] === "COMMON") {
							return {
								name: "COMMON",
								label: "COMMON",
								price: 0,
								priceDisplay: '-',
							}
						}

						let label = entry[0].split("_").join(" ")
						let priceDisplay = `$${(entry[1].usdCentPrice / 100).toLocaleString(undefined, {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2
						})}`;
						if (entry[0] === "BLOCK_286") {
							label = "NAKAMOTO"
						} else if (entry[0] === "UNCOMMON_2022" || entry[0] === "UNCOMMON_2023") {
							label = "UNCOMMON"
						} else if (entry[0] === "BLOCK_9") {
							label = "BLOCK9 450"
						} else if (entry[0] === "SILK_ROAD_HIT") {
							label = "SILK RD HIT"
						} else if (entry[0] === "SILK_ROAD_REGULAR") {
							label = "SILK ROAD"
						} else if (entry[0] === "PALINDROME_NUMERIC") {
							label = "PALINDROME"
						} else if (entry[0] === "HALLOWEEN_2009") {
							label = "HALLOWEEN"
						} else if (entry[0] === "CHRISTMAS_2009") {
							label = "CHRISTMAS"
						} else if (entry[0] === "FIRST_HALVING") {
							label = "1ST HALVING"
						} else if (entry[0] === "BLOCK_666") {
							label = "BLOCK 666"
						}

						return {
							name: entry[0],
							label,
							price: entry[1].usdCentPrice,
							priceDisplay,
							pricingInfo: entry[1]
						}
					})
					.filter(item => item.pricingInfo?.available.utxoCount !== 0) // Exclude items where utxoCount is 0
					.sort((a, b) => b.price - a.price); // Sorting in descending order by price
				setTypes(prices)
			} catch (error) {
				console.log("error", error)
			}
		})()
	}, [])
	const [selectedTypeIndex, setSelectedTypeIndex] = useState(0)

	const [files, setFiles] = useState<File[]>([]);
	const onDrop = (acceptedFiles: File[]) => {
		if (acceptedFiles.some(file => file.size > 400000)) {
			// Replace with real notification
			window.alert("File size too large")
			return
		}
		if (selectedTab === Tabs.BYOS) {
			if (acceptedFiles.length > 1){
				window.alert("You can only select one file for BYOS.")
				return
			}
		}
		setFiles(acceptedFiles);
	};
	const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

	const [params] = useSearchParams()
	const [selectedTab, setSelectedTab] = useState<Tabs>(params.get("type") as Tabs || Tabs.File)
	const [textContent, setTextContent] = useState("")
	const [uninscribedCount, setUninscribedCount] = useState(1)
	const onTabSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedTab(e.target.value as Tabs)
		setTab(e.target.value as Tabs)
		setFiles([])
		setTextContent("")
		setUninscribedCount(1)

		// Uninscribed can't be used with COMMON
		if (e.target.value === Tabs.Uninscribed && types[selectedTypeIndex]?.name === "COMMON") {
			setSelectedTypeIndex(0)
		}

	}

	useEffect(() => {
		setTab(selectedTab)
	}, [])

	const availableTypes = useMemo<SatPricing[]>(() => {
		if (!types || !types.length) return []
		if (selectedTab === Tabs.Uninscribed) {
			return types.filter(type => type.name !== "COMMON")
		}
		return types
	}, [types, selectedTypeIndex, selectedTab])

	const [transactionStatus, setTransactionStatus] = useState<StatusResponse | null>(
		// null
		//   {
		//   address: "bc1p7rvmmr9mgg823x66vyvwx5kxxc0j6epl3773e0ud6uylpwfl83esudfupu",
		//   amount: 13132123,
		//   expiration: 2687213010207,
		//   fee_rate: 13,
		//   id: "sdfafdsdfsfasd",
		//   service_fee: 13,
		//   size: 12332,
		//   status: "awaiting-confirmation",
		// }
		// {
		//   "id": "6ba620f8-6c6c-4670-896c-272f1af85564",
		//   "status": "awaiting-confirmation",
		//   "address": "bc1pnqxsgfptfkquslpc6fcyhnr33g4fwkf38quqrxuxg2fc2m6pva7qpxat9t",
		//   "fee_rate": 46,
		//   "amount": 3741565,
		//   "expiration": 2687462784,
		//   txid: "asdfsfsafsdfafs",
		//   service_fee: 13,
		//   size: 12332,
		// }
	)

	const onTryAgain = () => {
		setTransactionStatus(null)
	}

	const openInscribeFeeModalPopup = () => {
		setInscriptionDataConfiguration(curVal => ({
			...curVal,
			rarity: selectedTab === Tabs.BYOS_Attention ? {
				name: '',
				label: '',
				price: 0,
				priceDisplay: '',
			} : types[selectedTypeIndex],
			files,
			textContent,
			challengeId: null,
			challengeSignature: null,
			challengeAddress: null,
			type: selectedTab,
			uninscribedCount,
			inscribe_provided_own_ordinal: selectedTab === Tabs.BYOS_Attention ? "on" : null,
		}))
		// Moved to Inscribe.tsx
		// setInscriptionStep(2);

		// setInscribeFeeModalPopupOpen(true);
	};

	// If the sat type changes, update the max uninscribed count accordingly
	useEffect(() => {
		if (uninscribedCount > (types[selectedTypeIndex]?.pricingInfo?.available.utxoCount || 1)) {
			setUninscribedCount(types[selectedTypeIndex]?.pricingInfo?.available.utxoCount || 1)
		}
	}, [selectedTypeIndex])

	const closeInscribeFeeModalPopup = () => {
		setInscribeFeeModalPopupOpen(false);
	}

	useEffect(() => {
		setTab(selectedTab)
	}, [selectedTab])

	if (transactionStatus) {
		return <div className={styles.inscriberUi}>
			<TransactionStatus
				statusResponse={transactionStatus}
				onTryAgain={onTryAgain}
				statusEndpoint={CHECK_STATUS_ENDPOINT}
			/>
		</div>
	}

	const onAttentionNext = () => {
		openInscribeFeeModalPopup()
	}

	return (
		<>
			{!isInscribeFeeModalPopupOpen && (
				<div className={styles.inscriberUi}>
					{selectedTab === Tabs.BYOS_Attention ? <>
						<BYOSAttention
							onNext={onAttentionNext}
						/>
					</> : <>
						{selectedTab !== Tabs.BYOS && <div className={styles.satTypeSelector} style={{overflowX: "scroll"}}>
							<div className={styles.satTypes}>
								{availableTypes.map((type, index) => (
									<SatTypeTile
										key={index}
										isSelected={selectedTypeIndex === index}
										pricing={type}
										onTileClicked={() => setSelectedTypeIndex(index)}
									/>
								))}
							</div>
						</div>}
						<div className={styles.tabContainer}>
							<div className={styles.tabs}>
								<input type="radio" id="radio-1" name="tabs"
									value={Tabs.File}
									checked={selectedTab === Tabs.File}
									onChange={onTabSelected}
								/>
								<label className={styles.tab} htmlFor="radio-1">File</label>

								<input type="radio" id="radio-2" name="tabs"
									value={Tabs.Text}
									checked={selectedTab === Tabs.Text}
									onChange={onTabSelected}
								/>
								<label className={styles.tab} htmlFor="radio-2">Text</label>

								<input type="radio" id="radio-3" name="tabs"
									value={Tabs.Uninscribed}
									checked={selectedTab === Tabs.Uninscribed}
									onChange={onTabSelected}
								/>
								<label className={styles.tab} htmlFor="radio-3">Uninscribed</label>

								<input type="radio" id="radio-4" name="tabs"
									value={Tabs.BYOS}
									checked={selectedTab === Tabs.BYOS}
									onChange={onTabSelected}
								/>
								<label className={styles.tab} htmlFor="radio-4">BYOS</label>

								<span className={styles.glider}></span>
							</div>
						</div>
						{(selectedTab === Tabs.File || selectedTab === Tabs.BYOS) && <>
							<div {...getRootProps()} className={`${styles.uploadContainer} dropzone ${isDragActive ? 'active' : ''}`}>
								{selectedTab === Tabs.BYOS ?
									<input {...getInputProps()} multiple={false} /> :
									<input {...getInputProps()} />
								}
								{files.length > 0 ? (
									<div style={{
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "center",
										overflowX: "hidden",
										textOverflow: "ellipsis",
										width: "100%",
										padding: "10px"
									}}>
										<b className={styles.uploadLabel}>
											{selectedTab === Tabs.BYOS ? "Selected File" : `Selected Files (${files.length})`}
										</b>
										<em style={{
											fontSize: "12px",
											color: "#f7931a"
										}}></em>
										{files.map(file => (
											<div className={styles.dragDropLabel} key={file.name} style={{
												overflowX: "hidden",
												textOverflow: "ellipsis"
											}}>
												{file.name} ({file.size / 1000} kb)
											</div>
										))}
									</div>
								) : (<>
									<b className={styles.uploadLabel}>
										{selectedTab === Tabs.BYOS ? "Upload File" : `Upload Files`}
									</b>
									<div className={styles.dragDropLabel}>
										{selectedTab === Tabs.BYOS ? "Drag and drop your file here, or click to select file" : "Drag and drop your files here, or click to select files"}
									</div>
									<div className={styles.supportedFiletypeLabel}>
									  <span className={styles.supportedFiletypeLabelTxt}>
										<p className={styles.jpgPngGif}>
										  .jpg, .png, .gif, .webp, mp3, .mp4, .txt, .html + more!
										</p>
										<p className={styles.jpgPngGif}>&nbsp;</p>
										<p className={styles.jpgPngGif}>Max 400kb</p>
									  </span>
									</div>
								</>)}
							</div>
						</>}
						{selectedTab === "text" && <>
							<textarea
								className={styles.uploadContainerText}
								placeholder="Text goes here…"
								onChange={e => setTextContent(e.target.value)}
							/>
						</>}
						{selectedTab === "uninscribed" && <>
							<div className={styles.uninscribedHeader}>Purchase uninscribed, uncommon & exotic sats.</div>
							<div className={styles.uninscribedContainer}>
								<IconButton className={styles.uninscribedCountButton} onClick={() => setUninscribedCount(curVal => curVal > 1 ? curVal - 1 : 1)}>
									-
								</IconButton>
								<input
									className={styles.uploadContainerUninscribed}
									onChange={e => {
										const maxCount = types[selectedTypeIndex]?.pricingInfo?.available.utxoCount || 1;
										const newValue = e.target.valueAsNumber;
										setUninscribedCount(newValue > maxCount ? maxCount : newValue);
									}}
									type="number"
									value={uninscribedCount}
									max={types[selectedTypeIndex]?.pricingInfo?.available.utxoCount} />
								<IconButton className={styles.uninscribedCountButton} onClick={() => {
									const maxCount = types[selectedTypeIndex]?.pricingInfo?.available.utxoCount || 1;
									setUninscribedCount(curVal => (curVal < maxCount ? curVal + 1 : maxCount));
								}}>
									+
								</IconButton>
							</div>
						</>}
						<button
							className={styles.payButton}
							onClick={() => {
								if (selectedTab === Tabs.BYOS) {
									setSelectedTab(Tabs.BYOS_Attention)
									return
								}
								openInscribeFeeModalPopup()
							}}
							disabled={(selectedTab === Tabs.File && !files.length) || (selectedTab === Tabs.Text && !textContent.length) || (selectedTab === Tabs.BYOS && !files.length)}
						>
							<div className={styles.payButtonChild}/>
							<b className={styles.uploadPay}>{(selectedTab === Tabs.BYOS || selectedTab === Tabs.Uninscribed) ? "Next" : `Upload & Pay`}</b>
						</button>
					</>}
				</div>
			)}
		</>
	);
};

export default FileContainer;
