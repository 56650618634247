import { FunctionComponent, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./StoryContainer.module.css";
import {Tabs} from "../FileContainer/FileContainer";
const StoryContainer: FunctionComponent = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const onInscribeButtonClick = useCallback(() => {
    navigate("/inscribe");
  }, [navigate]);

	const onBuyButtonClick = useCallback(() => {
		navigate(`/inscribe?type=${Tabs.Uninscribed}`);
	}, [navigate]);

  return (
    <div className={styles.title}>
      <div className={styles.center}>
        <div className={styles.titleLabel} data-animate-on-scroll>
          <label className={styles.title1}>
            <p className={styles.everySatoshi}>
              <b>
                <span className={styles.every}>{`Something is `}</span>
                <span>{` `}</span>
              </b>
            </p>
            <p className={styles.everySatoshi}>
              <span className={styles.story}>Cooking.</span>
            </p>
          </label>
        </div>
	    </div>
    </div>
  );
};

export default StoryContainer;
