import {FunctionComponent, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Header from "../components/Header/Header";
import FileContainer, {Tabs} from "../components/FileContainer/FileContainer";
import styles from "./Inscribe.module.css";
import InscriptionFeeContainer from "../components/InscribeFeeContainer/InscribeFeeContainer";
import TransactionStatus from "../components/TransactionStatus/TransactionStatus";
import {
	CHECK_STATUS_ENDPOINT,
	CHISEL_CHECK_STATUS_ENDPOINT,
	InscriptionDataConfiguration
} from "../services/apiEndpoints";
import {Tooltip, Typography} from "@mui/material";
import {getAddress, GetAddressOptions} from "sats-connect";
import WalletSelector from "../components/WalletSelector/WalletSelector";
import Modal from "../components/Modal";
import BYOSFunding from "../components/FileContainer/BYOS/FundingAddress";
const Inscribe: FunctionComponent = () => {
	const params = useParams()
	const navigate = useNavigate();
	const currentYear = new Date().getFullYear();

	const [walletType, setWalletType] = useState(localStorage.getItem("walletType") || "")
	useEffect(() => {
		localStorage.setItem("walletType", walletType)
	}, [walletType])

	const [fundingAddress, setFundingAddress] = useState("")
	const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.File)
	const [connected, setConnected] = useState(false);
	const [accounts, setAccounts] = useState<string[]>([]);
	const [publicKey, setPublicKey] = useState("");
	const [address, setAddress] = useState("");
	const [xversePaymentAddress, setXversePaymentAddress] = useState("");
	const [balance, setBalance] = useState({
		confirmed: 0,
		unconfirmed: 0,
		total: 0,
	});
	const [network, setNetwork] = useState("livenet");
	const [inscriptionStep, setInscriptionStep] = useState(!!params.txid ? 0 : 1);
	const [inscriptionDataConfiguration, setInscriptionDataConfiguration] = useState<InscriptionDataConfiguration>({
		files: [],
		rarity: {
			name: '',
			label: '',
			price: -1,
			priceDisplay: ''
		},
		ordinalCount: 0,
		textContent: '',
		challengeId: null,
		challengeAddress: null,
		challengeSignature: null,
		type: Tabs.File,
		uninscribedCount: 0,
		aggregateFileSize: 0,
		inscribe_provided_own_ordinal: null,
	})
	// const [feeEstimationData, setFeeEstimationData] = useState<FeeEstimationData>({
	// 	dustPrevention: {aggregate: 0},
	// 	mining: {alignment: 0, commitment: 0, revelations: []},
	// 	service: 0,
	// 	total: 0
	// });

	const getBasicInfo = async () => {
		const unisat = (window as any).unisat;
		try {
			const [address] = await unisat.getAccounts();
			setAddress(address);

			const publicKey = await unisat.getPublicKey();
			setPublicKey(publicKey);

			const balance = await unisat.getBalance();
			setBalance(balance);

			const network = await unisat.getNetwork();
			setNetwork(network);
		} catch (error) {
			console.log("error", error)
		}
	};
	const selfRef = useRef<{ accounts: string[] }>({
		accounts: [],
	});
	const self = selfRef.current;
	const handleAccountsChanged = (_accounts: string[]) => {
		console.log("handling accounts", _accounts)
		if (self.accounts[0] === _accounts[0]) {
			// prevent from triggering twice
			return;
		}
		self.accounts = _accounts;
		if (_accounts.length > 0) {
			setAccounts(_accounts);
			setConnected(true);

			setAddress(_accounts[0]);

			getBasicInfo();
		} else {
			setConnected(false);
		}
	};

	const handleNetworkChanged = (network: string) => {
		setNetwork(network);
		getBasicInfo();
	};
	const logoutOfWallet = () => {
		setAccounts([])
		setConnected(false)
		setAddress("")
		setXversePaymentAddress("")
		self.accounts = []
		localStorage.removeItem("walletType")
		localStorage.removeItem("address")
		localStorage.removeItem("xversePaymentAddress")
		localStorage.removeItem("publicKey")
	}

	const onAPIClick = async () => {
		window.open("https://docs.satscribe.xyz/", "_blank");
	}
	const onConnectWalletClick = async () => {
		if (!connected) {
			setShowWalletSelector(true); // Show WalletSelector if not connected
		} else {
			logoutOfWallet()
		}
	}
	const connectUnisat = async () => {
		setWalletType("unisat")
		localStorage.setItem("walletType", walletType)
		const unisat = (window as any).unisat;
		if (!unisat) {
			window.location.href = "https://unisat.io";
		} else {
			console.log("unisat", unisat)

			try {
				const result = await unisat.requestAccounts();
				handleAccountsChanged(result);
			} catch (error) {
				console.log("error", error)
			}
		}
		setShowWalletSelector(false);
	}

	const [showWalletSelector, setShowWalletSelector] = useState(false);
	const connectXverse = async () => {
		setWalletType("xverse")
		localStorage.setItem("walletType", walletType)
		// const getAddressOptions: GetAddressOptions = {
		// 	payload: {
		// 		purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment], // You may need to update these values based on your specific types
		// 		message: "Connect to SatScribe.xyz",
		// 		network: {
		// 			type: BitcoinNetworkType.Mainnet
		// 		},
		// 	},
		// 	onFinish: (response: any) => {
		// 		if (response && response.addresses && response.addresses.length > 1) {
		// 			// Using the state setters instead of this.setState
		// 			//setOrdinalsAddress(response.addresses[0].address);
		// 			setXversePaymentAddress(response.addresses[1].address);
		// 			localStorage.setItem("xversePaymentAddress", response.addresses[1].address)
		// 			//setOrdinalsPublicKey(response.addresses[0].publicKey);
		// 			//setPaymentPublicKey(response.addresses[1].publicKey);
		// 			setAddress(response.addresses[0].address);
		// 			setPublicKey(response.addresses[0].publicKey);
		// 			localStorage.setItem("address", response.addresses[0].address)
		// 			localStorage.setItem("publicKey", response.addresses[0].address)
		// 			setConnected(true)
		// 		} else {
		// 			// Handle the error case here, such as setting default values or showing an error message
		// 		}
		// 		setShowWalletSelector(false);
		// 	},
		// 	onCancel: () => alert("Request canceled"),
		// };
		// await getAddress(getAddressOptions);
	};

	useEffect(() => {
		if (walletType !== "unisat") return;
		const unisat = (window as any).unisat;
		if (!unisat) return

		try {
			unisat.getAccounts().then((accounts: string[]) => {
				handleAccountsChanged(accounts);
			});

			unisat.on("accountsChanged", handleAccountsChanged);
			unisat.on("networkChanged", handleNetworkChanged);
		} catch (error) {
			console.log("error", error)
		}

		return () => {
			try {
				unisat.removeListener("accountsChanged", handleAccountsChanged);
				unisat.removeListener("networkChanged", handleNetworkChanged);
			} catch (error) {
				console.log("error", error)
			}
		};
	}, []);
	/**
	 * Load xverse data on mount
	 */
	useEffect(() => {
		if (localStorage.getItem("walletType") !== "xverse") return;
		setConnected(true)
		setAddress(localStorage.getItem("address") || "");
		setPublicKey(localStorage.getItem("publicKey") || "");
	}, []);

	const [id, setId] = useState(params.txid)

	const [tooltipOpen, setTooltipOpen] = useState(false);
	// Function to toggle tooltip on click
	const handleTooltipClick = () => {
		setTooltipOpen(!tooltipOpen);
	};

	const handleTooltipMouseEnter = () => {
		setTooltipOpen(true);
	};

	const handleTooltipMouseLeave = () => {
		setTooltipOpen(false);
	};

	// Function to close tooltip if clicked outside
	const handleClickOutside = (e: MouseEvent) => {
		if ((e.target as Element).className !== styles.pizzaCouponCode) {
			setTooltipOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	// FeeSelectionContainer needs the updated inscriptionDataConfiguration before getting rendered
	// This will guarantee that the UI won't be changed until after inscriptionDataConfiguration changes
	useEffect(() => {
		console.log('detected update in inscriptionDataConfiguration', inscriptionDataConfiguration)

		// Since this gets triggered on mount, make sure that there are values so we know a user driven change occurred
		if (inscriptionDataConfiguration.files.length || inscriptionDataConfiguration.uninscribedCount || inscriptionDataConfiguration.textContent) {
			setInscriptionStep(2)
		}
	}, [inscriptionDataConfiguration])

	const getWhitePart = () => {
		if (selectedTab === Tabs.Uninscribed) return "Buy"
		if (selectedTab === Tabs.BYOS) return "Bring Your Own"
		if (selectedTab === Tabs.BYOS_Attention && inscriptionStep === 1) return "Two"
		if (selectedTab === Tabs.BYOS_Attention && inscriptionStep !== 1) return "Funding"
		return "Inscribe"
	}
	const getYellowPart = () => {
		if (selectedTab === Tabs.BYOS) return "Rare Satoshis"
		if (selectedTab === Tabs.BYOS_Attention) return "Transaction"
		return "Satoshis";
	}

	return (
		<div className={styles.inscribe} style={{
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "space-between",
			overflowX: "hidden"
		}}>
			<div>
				<Header
					twitter="/twitter@2x.png"
					discord="/discord@2x.png"
					onConnectWalletClick={onConnectWalletClick}
					onAPIClick={onAPIClick}
					walletAddress={address}
				/>
				<Modal show={showWalletSelector} onClose={() => setShowWalletSelector(false)}>
					<WalletSelector onConnectUnisat={connectUnisat} onConnectXverse={connectXverse} />
				</Modal>
				<div className={styles.title}>
					<div className={styles.byosInner}>
						<b>{getWhitePart()}&nbsp;</b>
						<span className={styles.satoshis}>{getYellowPart()}</span>
					</div>
				</div>
				<section id={styles.inscription_wizard}>
					{inscriptionStep === 1 && <section id={styles.coupon_code}>
						<div className={styles.inscribeTopSection}>
							<div className={styles.homeButton} onClick={() => window.location.href = '/'}>Home</div>
							{!selectedTab.includes("byos") && <Tooltip
								title={<Typography variant="subtitle2">
									Receive 25% off by holding a <a className={styles.pizzaCouponCodeLink}
																	href={"https://magiceden.io/ordinals/marketplace/pizza_artifacts"}
																	target="_blank">Pizza Artifacts</a>
								</Typography>}
								placement="top-start"
								open={tooltipOpen}
								onOpen={() => setTooltipOpen(true)}
								onClose={() => setTooltipOpen(false)}
								// onMouseEnter={handleTooltipMouseEnter}
								// onMouseLeave={handleTooltipMouseLeave}
								arrow={true}
								leaveDelay={2000}
							>
								<div className={styles.pizzaCouponCode} style={{
									border: '2px solid gray',
									cursor: 'not-allowed',
									color: 'gray'
								}} onClick={handleTooltipClick}>🍕 Coupon
								</div>
							</Tooltip>}

							{/*<input*/}
							{/*	className={styles.couponCode}*/}
							{/*	type="text"*/}
							{/*	placeholder="Coupon Code"*/}
							{/*	value={state.coupon}*/}
							{/*	onChange={event => setState(currentValue => ({...currentValue, coupon: event.target.value}))}*/}
							{/*	style={{color: (state.coupon.trim() && couponData?.discount) ? "white" : "red"}}*/}
							{/*/>*/}
						</div>
					</section>}
					<section id={styles.file_container}>
						{inscriptionStep === 1 && (
							<FileContainer
								setInscriptionStep={setInscriptionStep}
								setInscriptionDataConfiguration={setInscriptionDataConfiguration}
								setTab={setSelectedTab}
								setFundingAddress={setFundingAddress}
							/>)}
					</section>
					<section id={styles.insription_fee_container}>
						{inscriptionStep === 2 && inscriptionDataConfiguration && (
							<InscriptionFeeContainer
								setInscriptionStep={setInscriptionStep}
								inscriptionDataConfiguration={inscriptionDataConfiguration}
								connectedWalletAddress={address}
								setInscriptionDataConfiguration={setInscriptionDataConfiguration}
							/>
						)}
						{Boolean(inscriptionStep === 0 && id) && <>
							{selectedTab === Tabs.BYOS_Address ? (
								<BYOSFunding fundingAddress={fundingAddress} />
							) : (
								<TransactionStatus
									statusResponse={{ id: id! }}
									onTransactionStatusNext={() => {
										if (fundingAddress) {
											setSelectedTab(Tabs.BYOS_Address)
										}
									}}
									statusEndpoint={inscriptionDataConfiguration.type === Tabs.BYOS_Attention ? CHISEL_CHECK_STATUS_ENDPOINT : CHECK_STATUS_ENDPOINT}
								/>
							)}
						</>}
					</section>
				</section>
			</div>
		</div>
	);
};

export default Inscribe;
