import {FunctionComponent, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import Header from "../components/Header/Header";
import StoryContainer from "../components/HomePage/StoryContainer";
import TrendingChecksContainer from "../components/HomePage/TrendingChecksContainer";
import FAQAccordion from '../components/FAQAccordion/FAQAccordion';
import styles from "./Home.module.css";
import WalletSelector from "../components/WalletSelector/WalletSelector";
import Modal from "../components/Modal";
import {GetAddressOptions, GetAddressResponse, BitcoinNetwork, getAddress} from 'sats-connect'
import RaffleContainer from "../components/RaffleContainer/RaffleContainer";

const Home: FunctionComponent = () => {
  const navigate = useNavigate();

  const [walletType, setWalletType] = useState(localStorage.getItem("walletType") || "")
	useEffect(() => {
		localStorage.setItem("walletType", walletType)
	}, [walletType])

  const [connected, setConnected] = useState(false);
  const [accounts, setAccounts] = useState<string[]>([]);
  const [publicKey, setPublicKey] = useState("");
  const [address, setAddress] = useState("");
  const [balance, setBalance] = useState({
    confirmed: 0,
    unconfirmed: 0,
    total: 0,
  });
  const [network, setNetwork] = useState("livenet");
  const getUnisatBasicInfo = async () => {
    const unisat = (window as any).unisat;
    try {
      const [address] = await unisat.getAccounts();
      setAddress(address);
  
      const publicKey = await unisat.getPublicKey();
      setPublicKey(publicKey);
  
      const balance = await unisat.getBalance();
      setBalance(balance);
  
      const network = await unisat.getNetwork();
	  debugger
      setNetwork(network);
    } catch (error) {
      console.log("error", error)
    }
  };

	const [ordinalsAddress, setOrdinalsAddress] = useState("");
	const [paymentAddress, setPaymentAddress] = useState("");
	const [ordinalsPublicKey, setOrdinalsPublicKey] = useState("");
	const [paymentPublicKey, setPaymentPublicKey] = useState("");

  const selfRef = useRef<{ accounts: string[] }>({
    accounts: [],
  });
  const self = selfRef.current;
  const handleAccountsChanged = (_accounts: string[]) => {
	  console.log("handling accounts", _accounts)
    if (self.accounts[0] === _accounts[0]) {
      // prevent from triggering twice
      return;
    }
    self.accounts = _accounts;
    if (_accounts.length > 0) {
      setAccounts(_accounts);
      setConnected(true);

      setAddress(_accounts[0]);

		getUnisatBasicInfo();
    } else {
      setConnected(false);
    }
  };

	const handleNetworkChanged = async (network: string) => { // Marked as async
		setNetwork(network);
		if (network === "livenet") {
			getUnisatBasicInfo();
		} else {
			//getXverseBasicInfo();
		}
	};

	const logoutOfWallet = () => {
		setAccounts([])
		setConnected(false)
		setAddress("")
		self.accounts = []
		localStorage.removeItem("xversePaymentAddress")
		localStorage.removeItem("walletType")
		localStorage.removeItem("address")
		localStorage.removeItem("publicKey")
	}

	const onAPIClick = async () => {
		window.open("https://docs.satscribe.xyz/", "_blank");
	}
  const onConnectWalletClick = async () => {
	  if (!connected) {
		  setShowWalletSelector(true); // Show WalletSelector if not connected
	  } else {
		  logoutOfWallet()
	  }
  }

	const connectUnisat = async () => {
		setWalletType("unisat")
		localStorage.setItem("walletType", walletType)
		const unisat = (window as any).unisat;
		if (!unisat) {
			window.location.href = "https://unisat.io";
		} else {

			try {
				const result = await unisat.requestAccounts();
				handleAccountsChanged(result);
			} catch (error) {
				console.log("error", error);
			}
		}

		setShowWalletSelector(false); // Close the WalletSelector modal
	};

	const connectXverse = async () => {
		setWalletType("xverse")
		localStorage.setItem("walletType", walletType)
		// const getAddressOptions: GetAddressOptions = {
		// 	payload: {
		// 		purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment], // You may need to update these values based on your specific types
		// 		message: "Connect to SatScribe.xyz",
		// 		network: {
		// 			type: BitcoinNetworkType.Mainnet
		// 		}
		// 	},
		// 	onFinish: (response: any) => {
		// 		if (response && response.addresses && response.addresses.length > 1) {
		// 			// Using the state setters instead of this.setState
		// 			//setOrdinalsAddress(response.addresses[0].address);
		// 			setPaymentAddress(response.addresses[1].address);
		// 			localStorage.setItem("xversePaymentAddress", response.addresses[1].address)
		// 			//setOrdinalsPublicKey(response.addresses[0].publicKey);
		// 			//setPaymentPublicKey(response.addresses[1].publicKey);
		// 			setAddress(response.addresses[0].address);
		// 			setPublicKey(response.addresses[0].publicKey);
		// 			localStorage.setItem("address", response.addresses[0].address)
		// 			localStorage.setItem("publicKey", response.addresses[0].address)
		// 			setConnected(true);
		// 		} else {
		// 			// Handle the error case here, such as setting default values or showing an error message
		// 		}
		// 		setShowWalletSelector(false);
		// 	},
		// 	onCancel: () => alert("Request canceled"),
		// };
		// await getAddress(getAddressOptions);
	};

  const [showWalletSelector, setShowWalletSelector] = useState(false);

	/**
	 * Load unisat data on mount
	 */
	useEffect(() => {
	  if (walletType !== "unisat") return;
    const unisat = (window as any).unisat;
    if (!unisat) return
      
    try {
      unisat.getAccounts().then((accounts: string[]) => {
        handleAccountsChanged(accounts);
      });
  
      unisat.on("accountsChanged", handleAccountsChanged);
      unisat.on("networkChanged", handleNetworkChanged);
    } catch (error) {
      console.log("error", error)
    }

    return () => {
      try {
        unisat.removeListener("accountsChanged", handleAccountsChanged);
        unisat.removeListener("networkChanged", handleNetworkChanged);
      } catch (error) {
        console.log("error", error)
      }
    };
  }, []);

	/**
	 * Load xverse data on mount
	 */
	useEffect(() => {
		if (localStorage.getItem("walletType") !== "xverse") return;
		setAddress(localStorage.getItem("address") || "");
		setPublicKey(localStorage.getItem("publicKey") || "");
	}, []);


  return (
    <div className={styles.home}>
      <Header
        twitter="/twitter@2x.png"
        discord="/discord@2x.png"
        onConnectWalletClick={onConnectWalletClick}
		  onAPIClick={onAPIClick}
        walletAddress={address}
      />
		<Modal show={showWalletSelector} onClose={() => setShowWalletSelector(false)}>
			<WalletSelector onConnectUnisat={connectUnisat} onConnectXverse={connectXverse} />
		</Modal>
      <StoryContainer />
    </div>
  );
};

export default Home;
