import {SatTypePricing, Tabs} from "../components/FileContainer/FileContainer";

export const API_BASE_URL = 'https://api.satscribe.xyz/api';
export const SAT_TYPE_PRICING_ENDPOINT = `${API_BASE_URL}/pricing/ordinals`
export const INSCRIBE_ENDPOINT = `${API_BASE_URL}/inscribe`;
export const CHISEL_INSCRIBE = "https://chisel.xyz/api/inscribe";
export const CHECK_STATUS_ENDPOINT = `${API_BASE_URL}/inscription/{inscription_id}/status`;
export const CHISEL_CHECK_STATUS_ENDPOINT = `https://chisel.xyz/api/inscription/{inscription_id}/status`;
export const FEE_RATES_ENDPOINT = `${API_BASE_URL}/fees`;
export const FEE_ESTIMATE_RATES_ENDPOINT = `${API_BASE_URL}/fees/estimate`;
export const BITCOIN_PRICE_ENDPOINT = `${API_BASE_URL}/pricing/bitcoin`;
export const BROADCAST_ENDPOINT = 'https://blockchain.info/pushtx';
export const COUPON_ENDPOINT = `${API_BASE_URL}/coupon/{coupon_code}`;
export const BITCOIN_PRICE_ENDPOINT_V2 = `${API_BASE_URL}/pricing/ordinals?v=2`
export const VERIFY_PIZZA_ARTIFACT_HOLDER = `${API_BASE_URL}/challenge/{wallet_address}/verify-pizza`
export const GENERATE_PIZZA_ARTIFACT_COUPON_CHALLENGE = `${API_BASE_URL}/challenge`

export interface SatPricing { 
    name: string; 
    label: string;
    price: number; 
    priceDisplay: string;
	pricingInfo?: SatTypePricing;
}

export type InscriptionDataConfiguration = {
    rarity: SatPricing,
    files: File[],
    ordinalCount: number,
	textContent: string | null,
	challengeId: number | null,
	challengeAddress: string | null,
	challengeSignature: string | null,
	type: Tabs;
	uninscribedCount: number,
	aggregateFileSize: number,
	inscribe_provided_own_ordinal: string | null,
}

export type FeeEstimationData = {
	service: number,
	total: number,
	network: {
		mining: { alignment: number | null, commitment: number | null, revelations: number[] },
		dustPrevention: { aggregate: number },
	}
}
