import React from "react";
import styles from "./Modal.module.css";

type ModalProps = {
	show: boolean;
	onClose: () => void;
	children: React.ReactNode;
};

const Modal: React.FunctionComponent<ModalProps> = ({ show, onClose, children }) => {
	if (!show) {
		return null;
	}

	return (
		<div className={styles.modalOverlay} onClick={onClose}>
			<div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
				{children}
			</div>
		</div>
	);
};

export default Modal;
