import React from 'react';

const VideoComponent: React.FC = () => {
  return (
    <div>
      <video width="256" height="256" autoPlay loop muted>
        <source src="/inscribing.webm" type="video/webm" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoComponent;
