import APIService from './apiService';
import {FEE_ESTIMATE_RATES_ENDPOINT, FEE_RATES_ENDPOINT} from './apiEndpoints';

class FeeRatesService {
  async getFeeRates() {
    const url = FEE_RATES_ENDPOINT;
    return APIService.fetchData(url);
  }

	async getFeeEstimate(ordinalReceiveAddress: string, aggregateFileSize: number, fileCount: number, rarity: string, ordinal_count: number, raw_ordinal_purchase: boolean = false) {
		const url = FEE_ESTIMATE_RATES_ENDPOINT;

		const params: any = {
			ordinal_receive_address: ordinalReceiveAddress || "bc1plp04rkdm5re7s8qgnu2g5h498e0mqk5jde2ctlplrf07ya2p709q38lsl4",
			fee_rate: 1,
			size: aggregateFileSize,
			count: fileCount,
			raw_ordinal_purchase,
			split_revelations: true,
		}
		if (rarity && rarity !== "COMMON"){
			params.rarity = rarity
			params.ordinal_count = ordinal_count
		}

		return APIService.postData(url, params)
	}
}

export default new FeeRatesService();
