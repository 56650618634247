import {FormEvent, FunctionComponent, useEffect, useMemo, useState} from "react";
import FeeSelectionContainer from "../FeeSelectionContainer/FeeSelectionContainer";
import styles from "./InscribeFeeContainer.module.css";
import {getAddressInfo, Network, validate} from 'bitcoin-address-validation';
import apiService from "../../services/apiService";
import {
	BITCOIN_PRICE_ENDPOINT, CHECK_STATUS_ENDPOINT, CHISEL_CHECK_STATUS_ENDPOINT,
	CHISEL_INSCRIBE,
	FeeEstimationData,
	INSCRIBE_ENDPOINT,
	InscriptionDataConfiguration
} from "../../services/apiEndpoints";
import TransactionStatus, {StatusResponse} from "../TransactionStatus/TransactionStatus";
import CouponService from "../../services/couponService";
import {Tabs} from "../FileContainer/FileContainer";
import FeeService from "../../services/feeService";
import {Tooltip, Typography} from "@mui/material";
import {sendBtcTransaction, SendBtcTransactionOptions, signMessage, SignMessageOptions} from 'sats-connect'

type InscribeFeeContainerType = {
	onClose?: () => void;
	setInscriptionStep: (step: number) => void;
	inscriptionDataConfiguration: InscriptionDataConfiguration;
	connectedWalletAddress?: string;
	setInscriptionDataConfiguration: React.Dispatch<React.SetStateAction<InscriptionDataConfiguration>>;
};

interface CouponData {
	discount: number;
}

(BigInt.prototype as any).toJSON = function () {
	return this.toString()
}

const InscribeFeeContainer: FunctionComponent<InscribeFeeContainerType> = ({
	setInscriptionStep,
	inscriptionDataConfiguration,
	onClose,
	connectedWalletAddress,
	setInscriptionDataConfiguration,
}) => {

	const [state, setState] = useState({feeRate: 1, address: "", coupon: ""})
	const [isValidAddress, setIsValidAddress] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [bitcoinPricing, setBitcoinPricing] = useState<{
		satoshisPerUsdCent: number;
		usdPerBitcoin: number;
	}>({
		satoshisPerUsdCent: 0,
		usdPerBitcoin: 0,
	})
	const [couponData, setCouponData] = useState<CouponData | null>(null);
	const [userOwnsPizzaArtifacts, setUserOwnsPizzaArtifacts] = useState(false);

	useEffect(() => {
		console.log("checking wallet for pizza", connectedWalletAddress)
		if (connectedWalletAddress && connectedWalletAddress !== "") {
			CouponService.getVerifyPizzaArtifactHolderData(connectedWalletAddress)
				.then(response => {
					console.log(response);
					const owns_pizza_artifacts = response.owns_pizza_artifacts
					setUserOwnsPizzaArtifacts(owns_pizza_artifacts);
				})
				.catch(error => {
					console.error(error);
					// Handle the error
				});
		}
	}, [connectedWalletAddress]);

	useEffect(() => {
		//console.log("validating address")
		try {
			const addressInfo = getAddressInfo(state.address)
			const isValid = validate(state.address, Network.mainnet);
			// if (state.address.length === 0){
			//   //if no wallet is connected, allow non taproot
			//   setIsValidAddress(isValid)
			// }else{
			//   //if wallet is connected, only allow taproot
			//   setIsValidAddress(isValid && addressInfo.type === AddressType.p2tr)
			// }
			setIsValidAddress(isValid)

			//console.log("is valid btc address", isValid)
		} catch (error) {
			setIsValidAddress(false)
			//console.log("address validation error", error)
		}
	}, [state.address])

	useEffect(() => {
		(async () => {
			try {
				const response: {
					satoshisPerUsdCent: number;
					usdPerBitcoin: number;
				} = await apiService.fetchData(BITCOIN_PRICE_ENDPOINT)

				setBitcoinPricing(response)

			} catch (error) {
				console.log("error", error)
			}
		})()
	}, [])

	const [feeEstimationData, setFeeEstimationData] = useState<FeeEstimationData>({
		network: {
			dustPrevention: {aggregate: 0},
			mining: {alignment: 0, commitment: 0, revelations: []}
		},
		service: 0,
		total: 0
	});

	useEffect(() => {

		//don/t update if invalid address inputted
		if (state.address && !isValidAddress) return;
		
		//Now Get Ordinal Fee Estimates
		if (
			(inscriptionDataConfiguration.files.length || inscriptionDataConfiguration.uninscribedCount || inscriptionDataConfiguration.textContent)
			&&
			(inscriptionDataConfiguration.aggregateFileSize || inscriptionDataConfiguration.ordinalCount)
			&& inscriptionDataConfiguration.rarity !== null
		) {
			if (inscriptionDataConfiguration.type === Tabs.Uninscribed) {
				FeeService.getFeeEstimate(state.address, 0, 0, inscriptionDataConfiguration.rarity.name, inscriptionDataConfiguration.uninscribedCount, true)
					.then((data: FeeEstimationData) => {
						setFeeEstimationData(data)

					})
					.catch(error => console.error('Error fetching rates:', error));
			} else {
				FeeService.getFeeEstimate(state.address, inscriptionDataConfiguration.aggregateFileSize, inscriptionDataConfiguration.ordinalCount, inscriptionDataConfiguration.rarity.name, 0)
					.then((data: FeeEstimationData) => {
						setFeeEstimationData(data)
					})
					.catch(error => console.error('Error fetching rates:', error));
			}
		}
	}, [inscriptionDataConfiguration.aggregateFileSize, inscriptionDataConfiguration.ordinalCount, state.address, isValidAddress])

	const [totalFee, setTotalFee] = useState({
		satoshis: 0,
		cents: 0
	})
	const [baseServiceFee, setBaseServiceFee] = useState(0)
	const [networkSatInfo, setNetworkSatInfo] = useState({
		satCount: 0,
		priceInUsd: 0,
		displayPriceInUsd: "",
	})
	const [serviceFeeInfo, setServiceFeeInfo] = useState({
		base: {
			satoshis: 0,
			cents: 0
		},
		fileLength: {
			satoshis: 0,
			cents: 0
		}
	})

	const [transactionStatus, setTransactionStatus] = useState<StatusResponse | null>(
		// null
		//   {
		//   address: "bc1p7rvmmr9mgg823x66vyvwx5kxxc0j6epl3773e0ud6uylpwfl83esudfupu",
		//   amount: 13132123,
		//   expiration: 2687213010207,
		//   fee_rate: 13,
		//   id: "sdfafdsdfsfasd",
		//   service_fee: 13,
		//   size: 12332,
		//   status: "awaiting-confirmation",
		// }
		// {
		//   "id": "6ba620f8-6c6c-4670-896c-272f1af85564",
		//   "status": "awaiting-confirmation",
		//   "address": "bc1pnqxsgfptfkquslpc6fcyhnr33g4fwkf38quqrxuxg2fc2m6pva7qpxat9t",
		//   "fee_rate": 46,
		//   "amount": 3741565,
		//   "expiration": 2687462784,
		//   txid: "asdfsfsafsdfafs",
		//   service_fee: 13,
		//   size: 12332,
		// }
	)

	const onTryAgain = () => {
		setTransactionStatus(null)
	}


	/**
	 * Calculates all fees
	 */
	useEffect(() => {
		// No file selected

		if (!inscriptionDataConfiguration || inscriptionDataConfiguration.rarity === null) {
			return;
		}

		const textContent = inscriptionDataConfiguration.textContent;
		const files = inscriptionDataConfiguration.files;
		const rarity = inscriptionDataConfiguration.rarity;

		if (inscriptionDataConfiguration.type === Tabs.File && !inscriptionDataConfiguration.files.length) {
			window.alert("There must be one file")
			return
		}
		if (inscriptionDataConfiguration.type === Tabs.Text && !inscriptionDataConfiguration.textContent) {
			window.alert("There must text content")
			return
		}

		if ([Tabs.File.toString(), Tabs.Text.toString()].includes(inscriptionDataConfiguration.type)) {
			if (inscriptionDataConfiguration.files.length > 0 && inscriptionDataConfiguration.textContent)
				throw new Error('only one of files and text may be set');
		}

		// ordinal pricing
		let ordinalCount = inscriptionDataConfiguration.uninscribedCount
		if ([Tabs.File.toString(), Tabs.Text.toString()].includes(inscriptionDataConfiguration.type)) {
			ordinalCount = inscriptionDataConfiguration.textContent ? 1 : inscriptionDataConfiguration.files.length
		}

		const ordinalPriceCents = ordinalCount * inscriptionDataConfiguration.rarity.price;
		const ordinalPriceSatoshis = Math.ceil(ordinalPriceCents * bitcoinPricing.satoshisPerUsdCent);

		let aggregateFileSize = 1

		if ([Tabs.File.toString(), Tabs.Text.toString(), Tabs.BYOS.toString()].includes(inscriptionDataConfiguration.type)) {
			aggregateFileSize = textContent ? textContent.length : files.reduce((a, f) => a + f.size, 0);
		}
		// base service fee
		const baseServiceFeeSatoshis = 15000;
		const baseServiceFeeCents = baseServiceFeeSatoshis / bitcoinPricing.satoshisPerUsdCent;

		const fileLengthFeeSatoshis = Math.ceil(aggregateFileSize * 3 / 1000) * 1000;
		const fileLengthFeeCents = fileLengthFeeSatoshis / bitcoinPricing.satoshisPerUsdCent;

		// mining/network fee
		const INSCRIPTION_OUTPUT_SIZE = 10000;
		const networkFeeEstimateSatoshis = Math.ceil((aggregateFileSize + 7000) * state.feeRate * 0.25 + INSCRIPTION_OUTPUT_SIZE * ordinalCount);
		const networkFeeEstimateCents = Math.ceil(networkFeeEstimateSatoshis / bitcoinPricing.satoshisPerUsdCent);

		const total = {
			satoshis: ordinalPriceSatoshis + baseServiceFeeSatoshis + fileLengthFeeSatoshis + networkFeeEstimateSatoshis,
			cents: ordinalPriceCents + baseServiceFeeCents + fileLengthFeeCents + networkFeeEstimateCents
		}

		setTotalFee({
			satoshis: total.satoshis,
			cents: total.cents
		})

		setNetworkSatInfo({
			satCount: networkFeeEstimateSatoshis,
			priceInUsd: networkFeeEstimateCents / 100,
			displayPriceInUsd: (networkFeeEstimateCents / 100).toLocaleString(undefined, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			})
		});

		setServiceFeeInfo({
			base: {
				satoshis: baseServiceFeeSatoshis,
				cents: baseServiceFeeCents
			},
			fileLength: {
				satoshis: fileLengthFeeSatoshis,
				cents: fileLengthFeeCents
			}
		})

		//
		// const insriptFee = (10000 / bitcoinPricing.satoshisPerUsdCent) / 100;
		// setInscriptionFee(insriptFee);
		// const baseFee = (baseServiceFeeSatoshis / bitcoinPricing.satoshisPerUsdCent) / 100;
		// setBaseServiceFee(baseServiceFeeCents/100);
		//
		// const raritySatCount = (rarity.price * 100) * bitcoinPricing.satoshisPerUsdCent;
		setInscriptionDataConfiguration(curVal => ({
			...curVal,
			ordinalCount,
			aggregateFileSize
		}))


	}, [bitcoinPricing, state.feeRate, inscriptionDataConfiguration.rarity]);

	const [pizzaArtifactsChallengeSignature, setPizzaArtifactsChallengeSignature] = useState("");
	const [pizzaArtifactsChallengeId, setPizzaArtifactsChallengeId] = useState("");

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault()
		console.log("data", inscriptionDataConfiguration)
		setIsSubmitting(true)
		const inscriptionAPIParams: { [key: string]: any } = {
			fee_rate: state.feeRate,
			ordinal_receive_address: state.address,
			forward_output_ordinal: "on",
			split_revelations: true,
		}
		if (inscriptionDataConfiguration.rarity.name) {
			inscriptionAPIParams.rarity = inscriptionDataConfiguration.rarity.name
		} else {
			inscriptionAPIParams.inscribe_provided_own_ordinal = true
		}
		if (inscriptionDataConfiguration.type === Tabs.Uninscribed) {
			inscriptionAPIParams.raw_ordinal_purchase = "on"
			inscriptionAPIParams.ordinal_count = inscriptionDataConfiguration.uninscribedCount
		} else {
			inscriptionAPIParams.inscriptions = inscriptionDataConfiguration.files
		}

		if (state.coupon && state.coupon.trim()) {
			inscriptionAPIParams.coupon = state.coupon;
		}

		if (pizzaArtifactsChallengeId && connectedWalletAddress && pizzaArtifactsChallengeSignature) {
			inscriptionAPIParams['challenge[id]'] = pizzaArtifactsChallengeId;
			inscriptionAPIParams['challenge[address]'] = connectedWalletAddress;
			inscriptionAPIParams['challenge[signature]'] = pizzaArtifactsChallengeSignature;
		}

		try {
			let response: StatusResponse;
			if (inscriptionDataConfiguration.type === Tabs.BYOS_Attention) {
				response = await apiService.postInscription(CHISEL_INSCRIBE, inscriptionAPIParams, inscriptionDataConfiguration.textContent)
			} else {
				response = await apiService.postInscription(INSCRIBE_ENDPOINT, inscriptionAPIParams, inscriptionDataConfiguration.textContent)
			}

			const walletType = localStorage.getItem("walletType")
			setIsSubmitting(false)
			setTransactionStatus(response)

			//send payment with unisat
			if (walletType === "unisat") {
				try {
					const txid = await (window as any).unisat.sendBitcoin(
						response.address,
						response.amount
					);
					onClose?.()
				} catch (error) {
					console.log("unisat autosend error", error)
				}
			}else{
				//xverse send
				const xversePaymentAddress = localStorage.getItem("xversePaymentAddress")
				// const xverseSendBtcOptions: SendBtcTransactionOptions = {
				// 	payload: {
				// 		network: {
				// 			type: BitcoinNetworkType.Mainnet
				// 		},
				// 		recipients: [
				// 			{
				// 				address: response.address,
				// 				amountSats: BigInt(response.amount),
				// 			},
				// 		],
				// 		senderAddress: xversePaymentAddress ? xversePaymentAddress : "",
				// 	},
				// 	onFinish: (response: any) => {
				// 		console.log('paid with xverse completed')
				// 		console.log(response)
				// 	},
				// 	onCancel: () => alert("Request canceled"),
				// };
				// await sendBtcTransaction(xverseSendBtcOptions);
			}

		} catch (error) {
			setIsSubmitting(false)
			window.alert((error as any).message)
			console.log("error", error)
		}
	}

	const totalPrice = useMemo<string>(() => {
		return (totalFee.cents / 100).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})
	}, [totalFee])

	useEffect(() => {
		if (state.coupon && state.coupon.trim() !== "") {
			CouponService.getCouponData(state.coupon)
				.then((data: CouponData) => {
					// Ignore 0% discounts
					if (!data.discount) return

					setDiscount({
						sats: 0,
						discountedCents: 0,
						price: "",
					})
					setCouponData(data)
				})
				.catch(error => {
					setCouponData(null)
					setDiscount({
						sats: 0,
						discountedCents: 0,
						price: "",
					})
					console.error('Error fetching coupon data:', error)
				});
		} else {
			setCouponData(null)
			setDiscount({
				sats: 0,
				discountedCents: 0,
				price: "",
			})
		}
	}, [state.coupon]);

	const [discount, setDiscount] = useState({
		sats: 0,
		price: "",
		discountedCents: 0,
	})

	useEffect(() => {
		if (!couponData || !totalFee.satoshis || !totalFee.cents || rarity === null) return

		const discountPercentage = couponData.discount / 100

		const discountedSats = (serviceFeeInfo.fileLength.satoshis + serviceFeeInfo.base.satoshis + (raritySatCount * ordinalCount)) * discountPercentage
		const discountedCents = ((serviceFeeInfo.fileLength.cents) + (serviceFeeInfo.base.cents) + ((rarity.price * ordinalCount))) * discountPercentage

		setDiscount({
			sats: discountedSats,
			price: (discountedCents / 100).toLocaleString(undefined, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			}),
			discountedCents
		})
	}, [couponData, totalFee])

	const rarity = inscriptionDataConfiguration.rarity;
	const ordinalCount = inscriptionDataConfiguration.ordinalCount || inscriptionDataConfiguration.uninscribedCount;

	//xverse connected
	const signXverseMessage = async () => {
		try {
			const response = await CouponService.generatePizzaArtifactCouponChallenge();
			//console.log(response);
			const challengeId = response.id;
			const unsignedChallenge = response.challenge;

			try {

				// const signMessageOptions: SignMessageOptions = {
				// 	payload: {
				// 		network: {
				// 			type: BitcoinNetworkType.Mainnet
				// 		},
				// 		address: connectedWalletAddress ?? "",
				// 		message: unsignedChallenge,
				// 	},
				// 	onFinish: (response: any) => {
				// 		setPizzaArtifactsChallengeSignature(response)
				// 		setPizzaArtifactsChallengeId(challengeId)
				// 		setDiscount({
				// 			sats: 0,
				// 			discountedCents: 0,
				// 			price: "",
				// 		})
				// 		setCouponData({discount: 25})
				// 	},
				// 	onCancel: () => alert("Request canceled"),
				// };
				// await signMessage(signMessageOptions);
			} catch (error) {
				console.log("error while signing message", error);
			}
		} catch (error) {
			console.error("error while generating challenge", error);
		}
	};

	const onApplyPizzaCouponClick = async () => {
		const walletType = localStorage.getItem("walletType")
		debugger
		if (walletType === "xverse") {
			signXverseMessage()
		}

		if (walletType === "unisat") {
			//unisat connected
			const unisat = (window as any).unisat;
			if (!unisat) {
				window.location.href = "https://unisat.io";
			} else {
				try {
					const response = await CouponService.generatePizzaArtifactCouponChallenge();
					//console.log(response);
					const challengeId = response.id;
					const unsignedChallenge = response.challenge;

					try {
						const signature = await unisat.signMessage(unsignedChallenge, "bip322-simple");
						setPizzaArtifactsChallengeSignature(signature)
						setPizzaArtifactsChallengeId(challengeId)
						setDiscount({
							sats: 0,
							discountedCents: 0,
							price: "",
						})
						setCouponData({discount: 25})
					} catch (error) {
						console.log("error while signing message", error);
					}
				} catch (error) {
					console.error("error while generating challenge", error);
				}
			}
		}

	}

	const raritySatCount = ((rarity?.price || 0) * bitcoinPricing.satoshisPerUsdCent);
	const serviceFeeMinusRarityFeeSatoshis = feeEstimationData.service - (ordinalCount * raritySatCount);
	const baseMiningFee = (feeEstimationData.network.mining.alignment ?? 0) + (feeEstimationData.network.mining.commitment ?? 0) + feeEstimationData.network.mining.revelations.reduce((a, b) => a + b, 0);
	const discountSatoshis = couponData ? Math.floor(feeEstimationData.service * (couponData.discount / 100)) : 0;
	const miningFee = baseMiningFee * state.feeRate;
	const networkFeeSatoshis = miningFee + feeEstimationData.network.dustPrevention.aggregate;

	if (transactionStatus) {
		return <div className={styles.inscriberUi}>
			<TransactionStatus
				statusResponse={transactionStatus}
				onTryAgain={onTryAgain}
				statusEndpoint={inscriptionDataConfiguration.type === Tabs.BYOS_Attention ? CHISEL_CHECK_STATUS_ENDPOINT : CHECK_STATUS_ENDPOINT}
			/>
		</div>
	}

	const pizzaCouponCodeStyles = (userOwnsPizzaArtifacts && connectedWalletAddress)
		? {}
		: {border: '2px solid gray', cursor: 'not-allowed', color: 'gray'};

	const adjustedTotalFeeCents = ((networkFeeSatoshis + (feeEstimationData.service - discountSatoshis)) / bitcoinPricing.satoshisPerUsdCent);
	return (
		<div className={styles.inscribeFeeContainer}>
			<div className={styles.inscribeTopSection}>
				<div className={styles.backButton} onClick={() => setInscriptionStep(1)}>Back</div>
				{!inscriptionDataConfiguration.type.includes("byos") &&<Tooltip
					title={<Typography variant="subtitle2">
						Receive 25% off by holding a <a className={styles.pizzaCouponCodeLink}
														href={"https://magiceden.io/ordinals/marketplace/pizza_artifacts"}
														target="_blank">Pizza Artifacts</a>
					</Typography>}
					placement="right"
					arrow={true}
				>
					<div className={styles.pizzaCouponCode} style={pizzaCouponCodeStyles}
						 onClick={userOwnsPizzaArtifacts ? () => onApplyPizzaCouponClick() : undefined}>Apply 🍕 Coupon
					</div>
				</Tooltip>}

				{/*<input*/}
				{/*	className={styles.couponCode}*/}
				{/*	type="text"*/}
				{/*	placeholder="Coupon Code"*/}
				{/*	value={state.coupon}*/}
				{/*	onChange={event => setState(currentValue => ({...currentValue, coupon: event.target.value}))}*/}
				{/*	style={{color: (state.coupon.trim() && couponData?.discount) ? "white" : "red"}}*/}
				{/*/>*/}
			</div>
			<form onSubmit={handleSubmit}>
				<FeeSelectionContainer
					selectedRate={state.feeRate}
					onRateSelected={newRate => setState(curVal => ({...curVal, feeRate: newRate}))}
					inscriptionDataConfiguration={inscriptionDataConfiguration}
				/>
				<div className={styles.invoiceContainer}>
					<table>
						{rarity.price > 0 && <tr>
							<td>{rarity.label} Sats Fee:</td>
							<td>
								{inscriptionDataConfiguration.ordinalCount || inscriptionDataConfiguration.uninscribedCount} x {raritySatCount.toLocaleString(undefined, {
								maximumFractionDigits: 0,
								minimumFractionDigits: 0
							})} sats
							</td>
							<td>
								~${
								bitcoinPricing.satoshisPerUsdCent > 0 ?
									((rarity.price * ordinalCount) / 100).toLocaleString(undefined, {
										maximumFractionDigits: 2,
										minimumFractionDigits: 2
									})
									:
									"-.--"
							}
							</td>
						</tr>}
						<tr>
							<td>Service Fee</td>
							<td>{serviceFeeMinusRarityFeeSatoshis.toLocaleString(undefined, {
								maximumFractionDigits: 0,
								minimumFractionDigits: 0
							})} sats
							</td>
							<td>
								~${
								bitcoinPricing.satoshisPerUsdCent > 0 ?
									((serviceFeeMinusRarityFeeSatoshis / bitcoinPricing.satoshisPerUsdCent) / 100).toLocaleString(undefined, {
										maximumFractionDigits: 2,
										minimumFractionDigits: 2
									})
									:
									"-.--"
							}
							</td>
						</tr>
						{couponData && <tr>
							<td>🍕 Coupon ({couponData.discount}%):</td>
							<td>
								-{Math.floor(discountSatoshis).toLocaleString(undefined, {
								minimumFractionDigits: 0,
								maximumFractionDigits: 0
							})} sats
							</td>
							<td>
								-${(discountSatoshis / bitcoinPricing.satoshisPerUsdCent / 100).toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2
							})}
							</td>
						</tr>}
						<tr>
							<td>Network Fee</td>
							<td>
								{networkFeeSatoshis.toLocaleString(undefined, {
									maximumFractionDigits: 0,
									minimumFractionDigits: 0
								})} sats
							</td>
							<td>
								~${bitcoinPricing.satoshisPerUsdCent > 0 ?
								(networkFeeSatoshis / bitcoinPricing.satoshisPerUsdCent / 100).toLocaleString(undefined, {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2
								})
								:
								"-.--"}
							</td>
						</tr>
						<tr className={styles.total}>
							<td>Total Fee</td>
							<td>
								{(networkFeeSatoshis + (feeEstimationData.service - discountSatoshis)).toLocaleString(undefined, {
									maximumFractionDigits: 0,
									minimumFractionDigits: 0
								})} sats
								{/*{(adjustedTotalFeeSatoshis - discount.sats).toLocaleString()} sats*/}
							</td>
							<td>
								~${bitcoinPricing.satoshisPerUsdCent > 0 ? (adjustedTotalFeeCents/100).toLocaleString(undefined, {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2
								})
								:
								"-.--"}
							</td>
						</tr>
					</table>
				</div>
				<input
					className={styles.bitcoinReceiveAddress}
					type="text"
					placeholder={inscriptionDataConfiguration.type === Tabs.Uninscribed ? "Xverse/ Sparrow Ordinal Receive Address" : "Bitcoin Ordinal Receive Address"}
					value={state.address}
					onChange={event => setState(currentValue => ({...currentValue, address: event.target.value}))}
					style={{color: isValidAddress ? "white" : "red"}}
				/>
				<button className={styles.payButton} type="submit" disabled={!state.address || !isValidAddress}>
					<div className={styles.payButtonChild}/>
					<b className={styles.submitPayment}>{isSubmitting ?
						<img src="loader.svg" className="spinner"/> : 'Submit Payment'}</b>
				</button>
			</form>
		</div>
	);
};

export default InscribeFeeContainer;
