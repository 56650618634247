import {FunctionComponent} from 'react';
import styles from './Header.module.css';
import {useNavigate} from 'react-router-dom';
import WalletSelector from "../WalletSelector/WalletSelector";
import Modal from "../Modal";

export function ellipseAddress(address = '', width = 5) {
	if (!address) {
		return ''
	}
	return `${address.slice(0, width)}...${address.slice(-width)}`
}

type HeaderType = {
	twitter?: string;
	discord?: string;
	walletAddress?: string;

	/** Action props */
	onFrameButtonClick?: () => void;
	onFrameButton1Click?: () => void;
	onConnectWalletClick?: () => void;
	onAPIClick?: () => void;
};

const Header: FunctionComponent<HeaderType> = ({
												   twitter,
												   discord,
												   walletAddress,
												   onFrameButtonClick,
												   onFrameButton1Click,
												   onConnectWalletClick,
												   onAPIClick
											   }) => {

	const nav = useNavigate()

	return (
		<div className={styles.nav}>
			<div className={styles.navInner}>
				<div className={styles.titleSocialParent}>
					<div className={styles.titleSocial}>
						<a href="/" className={styles.title}>
							<b className={styles.satscribe}>
								<span>Sat</span>
								<span className={styles.scribe}>Scribe</span>
							</b>
						</a>
						<div className={styles.socials}>
							<a href="https://twitter.com/satscribexyz"
							   target="_blank"
							   className={styles.twitterWrapper}
							>
								<img className={styles.twitterIcon} alt="" src={twitter}/>
							</a>
							<a href="https://discord.gg/hWHyS8uJCR"
							   target="_blank"
							   className={styles.twitterWrapper}
							>
								<img className={styles.twitterIcon} alt="" src={discord}/>
							</a>
						</div>
					</div>
					{/* <div className={styles.buttonGroup}>
						<button
							className={styles.requestApi}
							onClick={onAPIClick}>API</button>
						<button
							className={styles.connectWallet}
							onClick={onConnectWalletClick}
						>
							<div className={styles.connectWalletInner}>
								<div className={styles.connectWalletWrapper}>
									<b className={styles.connectWallet1}>{walletAddress ? ellipseAddress(walletAddress) : 'Connect Wallet'}</b>
								</div>
							</div>
						</button>
					</div> */}
				</div>
			</div>
		</div>
	);
};

export default Header;
