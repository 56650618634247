import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiService from "../../services/apiService";
import {CHECK_STATUS_ENDPOINT, CHISEL_CHECK_STATUS_ENDPOINT} from "../../services/apiEndpoints";
import QRCode from "react-qr-code";
import styles from "./TransactionStatus.module.css";
import VideoComponent from "../InscribingHUD"
import {ellipseAddress} from "../Header/Header";
import {Tabs} from "../FileContainer/FileContainer";

type Transaction = {
	txid: string,
	isBroadcast: boolean,
	isConfirmed: boolean
}

type TableRowProps = {
	data: Transaction
}

type TableProps = {
	data: Transaction[]
}



const TableRow: React.FC<TableRowProps> = ({ data }) => {
	const [hover, setHover] = useState(false);
	const prefix = data.isConfirmed ? 'https://ord.io/' : 'https://mempool.space/tx/';
	const suffix = data.isConfirmed ? 'i0' : '';
	const hrefUrl = `${prefix}${data.txid}${suffix}`;
	return (
		<tr>
			<td style={{
				overflow: 'hidden',
				textOverflow: 'ellipsis'
			}}>
				<a
					href={hrefUrl}
					target="_blank"
					style={{
						color: "#f7931a",
						marginTop: "30px",
						width: "100%",
						textDecoration: hover ? "underline" : "none",
						fontFamily: 'monospace'
					}}
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
				>
					{data.txid}
				</a>
			</td>
			<td>{data.isBroadcast ? "Yes" : "No"}</td>
			<td>{data.isConfirmed ? "Yes" : "No"}</td>
		</tr>
	)
}
// 9819461d-f858-4ac7-842c-6126a54d8f29
const TransactionStatus = ({
	id,
    statusResponse,
    onTryAgain,
	onTransactionStatusNext,
	statusEndpoint,
}: Props) => {

	const navigate = useNavigate();
	const [hasUpdatedUrl, setHasUpdatedUrl] = useState(false);

    const [latestStatusResponse, setLatestStatusResponse] = useState<{
		id?: string,
        address?: string,
		ordinalReceptacleAddress?: string,
        amount?: number,
        status?: string, // "awaiting-funding" | "awaiting-confirmation" | "confirmed" | "expired"
        expiration?: number,
		txid?: string,
        txids?: Transaction[],
    }>({ ...statusResponse })
	const [expired, setExpired] = useState(false)

    useEffect(() => {

		const getStatus = async () => {
			let newResponse: any = null

			// check primary status endpoint
			try {
				newResponse = await apiService.fetchData(statusEndpoint.replace("{inscription_id}", statusResponse.id!))
			} catch (error) {
				console.log("error", error)
			}

			// check fallback if the first status endpoint failed
			if (newResponse === null) {
				const fallbackApi = statusEndpoint === CHECK_STATUS_ENDPOINT ? CHISEL_CHECK_STATUS_ENDPOINT : CHECK_STATUS_ENDPOINT
				try {
					newResponse = await apiService.fetchData(fallbackApi.replace("{inscription_id}", statusResponse.id!))
				} catch (error) {
					console.log("error", error)
				}
			}

			if (!newResponse) return

			const sortedTxids = newResponse.txids?.sort((a: Transaction, b: Transaction) =>
				a.isBroadcast && !b.isBroadcast ? -1 : b.isBroadcast && !a.isBroadcast ? 1 : 0
			);
			setLatestStatusResponse(curVal => ({
				id: newResponse.id || curVal.id,
				address: newResponse.address || curVal.address,
				ordinalReceptacleAddress: newResponse.ordinal_receptacle_address || curVal.address,
				amount: newResponse.amount || curVal.amount,
				status: newResponse.status || curVal.status,
				expiration: newResponse.expiration || curVal.expiration,
				txids: sortedTxids || curVal.txids,
				txid: newResponse.txid || curVal.txid,
			}))
			if (!hasUpdatedUrl) {
				navigate(`/inscription/${newResponse.id}`);
				setHasUpdatedUrl(true);
			}
			if (newResponse.status === "confirmed" && interval) {
				clearInterval(interval)
				onTransactionStatusNext?.()
			}
		}

		// If a user is trying to check transaction status, we initially only have the id
		// Fetch the rest of the status details immediately
		if (statusResponse.id && !statusResponse.status) {
			getStatus()
		}

        const interval = setInterval(() => {
            getStatus()
          }, 10000)
        return () => {
            if (interval) {
                clearInterval(interval)
            }
        }
    }, [])
    const calculateTimeLeft = (): TimerData | null => {
		let timeLeft: TimerData = {};
		if (statusResponse.status === "awaiting-confirmation") return null
        if (!statusResponse.expiration)  return timeLeft
        if (expired) return timeLeft
        const difference = +new Date(statusResponse.expiration) - +new Date();

        if (difference > 0) {
          timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
          };
        } else if (statusResponse.status !== "expired") {
            setExpired(true)
        }
    
        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        if (expired) return 

        const timer = setInterval(() => {
            const timeLeft = calculateTimeLeft()
            if (timeLeft === null) {
                clearInterval(timer)
            } else {
                setTimeLeft(timeLeft);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return <>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "100%" }}>
            {Boolean(expired || latestStatusResponse.status === "expired") ? <>
                <h2 style={{ marginTop: "0px"}}>
                    Expired!
                </h2>
				{!!onTryAgain && <button
					className={styles.payButton}
					onClick={onTryAgain}
				>
					<div className={styles.payButtonChild}/>
					<b className={styles.uploadPay}>Try Again</b>
				</button>}
            </> : <>
				{latestStatusResponse.status === "awaiting-ordinal" && <>
					<p className={styles.amount}>
						Send Rare Sat
					</p>
					<span className={styles.receiveAddress}
						  onClick={() => navigator.clipboard.writeText(latestStatusResponse.ordinalReceptacleAddress!)}
					>
                        To: <b className={styles.bitcoinAddress}>{latestStatusResponse.ordinalReceptacleAddress!}</b>
                    </span>
					<p className={styles.disclaimer}>
						<em>only send the sat you want to inscribe on to this address.</em>
					</p>
					<div className={styles.qrCode}>
						<a href={`bitcoin:${latestStatusResponse.ordinalReceptacleAddress!}?amount=${satoshisToBitcoinDecimalString(latestStatusResponse.amount!)}`}>
							<QRCode
								value={`bitcoin:${latestStatusResponse.ordinalReceptacleAddress!}?amount=${satoshisToBitcoinDecimalString(latestStatusResponse.amount!)}`}
							/>
						</a>
					</div>
					<p className={styles.expiration}>
						Expires: {new Date(latestStatusResponse.expiration!).toLocaleString(undefined, { dateStyle: "long", timeStyle: "medium" })}
					</p>
					<p className={styles.remainingTime}>
						{timeLeft?.minutes || 0} minutes, {timeLeft?.seconds || 0} seconds
					</p>
				</>}
                {latestStatusResponse.status === "awaiting-funding" && <>
                    <p className={styles.amount}>
                        Send: <b className={styles.satAmount}>{latestStatusResponse.amount!.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</b> sats / <b className={styles.satAmount}>{satoshisToBitcoinDecimalString(latestStatusResponse.amount!)}</b> BTC
                    </p>
                    <span className={styles.receiveAddress}
                        onClick={() => navigator.clipboard.writeText(latestStatusResponse.address!)}
                    >
                        To: <b className={styles.bitcoinAddress}>{latestStatusResponse.address!}</b>
                    </span>
                    <p className={styles.disclaimer}>
                        <em>any amount below will be returned to the ordinal receive address.</em>
                    </p>
                    <div className={styles.qrCode}>
                        <a href={`bitcoin:${latestStatusResponse.address!}?amount=${satoshisToBitcoinDecimalString(latestStatusResponse.amount!)}`}>
                        <QRCode
                            value={`bitcoin:${latestStatusResponse.address!}?amount=${satoshisToBitcoinDecimalString(latestStatusResponse.amount!)}`}
                        />  
                        </a>
                    </div>
                    <p className={styles.expiration}>
                        Expires: {new Date(latestStatusResponse.expiration!).toLocaleString(undefined, { dateStyle: "long", timeStyle: "medium" })}
                    </p>
                    <p className={styles.remainingTime}>
                        {timeLeft?.minutes || 0} minutes, {timeLeft?.seconds || 0} seconds
                    </p>
                </>}
                {Boolean(latestStatusResponse.status === "awaiting-confirmation" || latestStatusResponse.status === "broadcasting") && <>
                    {/* <img src="/inscribing.webm" width={256} height={256}/> */}
					<VideoComponent />
					<div className={styles.transcations}>
						{latestStatusResponse.txids && <table style={{width: "100%", tableLayout: "fixed"}}>
							<thead>
							<tr>
								<th>Transaction</th>
								<th>Broadcast</th>
								<th>Confirmed</th>
							</tr>
							</thead>
							<tbody>
							{latestStatusResponse.txids?.map((row, index) => (
								<TableRow key={index} data={row}/>
							))}
							</tbody>
						</table>}
						{latestStatusResponse.txid && <table style={{width: "100%", tableLayout: "fixed"}}>
							<thead>
							<tr>
								<th>Transaction Id</th>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td style={{
									overflow: 'hidden',
									textOverflow: 'ellipsis'
								}}>
									<a
										href={`https://mempool.space/tx/${latestStatusResponse.txid}`}
										target="_blank"
										style={{
											color: "#f7931a",
											marginTop: "30px",
											fontFamily: 'monospace'
										}}
									>
										{latestStatusResponse.txid}
									</a>
								</td>
							</tr>
							</tbody>
						</table>}
					</div>

                </>}
                {latestStatusResponse.status === "confirmed" && <>
					<div className={styles.transcations}>
						{latestStatusResponse.txids && <table style={{width: "100%", tableLayout: "fixed"}}>
							<thead>
							<tr>
								<th>Transaction</th>
								<th>Broadcast</th>
								<th>Confirmed</th>
							</tr>
							</thead>
							<tbody>
							{latestStatusResponse.txids?.map((row, index) => (
								<TableRow key={index} data={row}/>
							))}
							</tbody>
						</table>}
						{latestStatusResponse.txid && <table style={{width: "100%", tableLayout: "fixed"}}>
							<thead>
								<tr>
									<th>Transaction</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td style={{
										overflow: 'hidden',
										textOverflow: 'ellipsis'
									}}>
										<a
											href={`https://mempool.space/tx/${latestStatusResponse.txid}`}
											target="_blank"
											style={{
												color: "#f7931a",
												marginTop: "30px",
												fontFamily: 'monospace'
											}}
										>
											{latestStatusResponse.txid}
										</a>
									</td>
								</tr>
							</tbody>
						</table>}
					</div>
                </>}

            </>}
        </div>
    </>
}

interface Props {
    statusResponse: Partial<StatusResponse>;
	onTransactionStatusNext?: () => void;
    onTryAgain?: () => void;
	id?: string;
	statusEndpoint: string;
}

export interface StatusResponse {
    address: string;
    amount: number;
    expiration: number;
    fee_rate: number;
    id: string;
    service_fee: number;
    size: number;
    status: string;
    txid?: string;
	coupon?: string;
}

interface TimerData { 
    days?: number; 
    hours?: number; 
    minutes?: number; 
    seconds?: number; 
}
function satoshisToBitcoinDecimalString(satoshis: number) {
    if (isNaN(satoshis)) {
      throw new Error('invalid satoshi amount');
    }
    if (isNaN(satoshis) || !Number.isFinite(satoshis) || !Number.isSafeInteger(satoshis)) {
      throw new Error('invalid satoshi amount');
    }
    if (satoshis < 0) {
      throw new Error('invalid satoshi amount');
    }

    const satoshiString = `${satoshis}`;
    // left pad the string to have at least one leading zero before the decimal
    const paddedSatoshiString = satoshiString.padStart(9, '0');
    const bitcoinComponent = paddedSatoshiString.substring(0, paddedSatoshiString.length - 8);
    const satoshiComponent = paddedSatoshiString.substring(paddedSatoshiString.length - 8);
    return `${bitcoinComponent}.${satoshiComponent}`;
  }

export default TransactionStatus
